//
// user.scss
// Use this to write your custom SCSS
//

.navbar-brand {
  padding-top: 0;
  margin-top: -3px;
}

.navbar-brand-img, .footer-brand {
  width: 136px;

}

.footer-brand {
  width: 100px;

}
